import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable({
   providedIn: 'root'
})
export class UtilityService {
   public status(status): string {
      if (status) {
         return 'Active'
      }
      return 'In-Active'
   }
   public dateDiffYears(dob): number {
      return moment().diff(dob, 'year');
   }
   public formatDate(date): string {
      return moment(date).format("MMM DD, yyyy");
   }
   public awardType(type): string {
      if(type == 'time'){
         return 'Ranking By Time';
      } else if (type == 'week'){
         return 'Ranking By Week';
      }else{
         return "All"
      }
   }
}
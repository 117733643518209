import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Company, User } from '../models';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  url = environment.api + 'company/';
  constructor(private http: HttpClient) { }

  public list(params: Params = null) {
    return this.http.get<{ count: number; data: Company[] }>(this.url, { params: params });
  }

  public get(id: number|string) {
    return this.http.get<Company>(this.url + id);
  }
  public update(id: number, data: Object) {
    return this.http.put(this.url + id, data);
  }
  public create(data: Object) {
    return this.http.post(this.url, data);
  }
  public delete(id: number) {
    return this.http.delete(this.url + id);
  }
  public getCompanyUsers(id: number | string) {
    return this.http.get<Company>(this.url +"company_users/" + id);
  }
  public csvDownload(id: number | string) {
    return this.http.get<User[]>(this.url + "downloadCSV/" + id);
  }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { environment } from 'src/environments/environment';
import { RecommendedForMain } from '../models';

@Injectable({
	providedIn: 'root'
})
export class MainRecomendedService {
	url = environment.api + 'mainrecommendedcontent/';
	constructor(private http: HttpClient) { }

	public list(params: Params = null) {
		return this.http.get<{ count: number; data: RecommendedForMain[] }>(this.url, { params: params });
	}

	public get(id: number) {
		return this.http.get<RecommendedForMain>(this.url + id);
	}
	public update(id: number, data: Object) {
		return this.http.put(this.url + id, data);
	}
	public create(data: Object) {
		return this.http.post(this.url, data);
	}
	public delete(id: number) {
		return this.http.delete(this.url + id);
	}
	public setMainRecommendedContent(data: Object) {
		return this.http.post(this.url + 'recommendedContent/', data);
	}
	public setCategoryRecommendedContent( data: Object) {
		return this.http.post(this.url + 'categoryRecomanded/', data);
	}
}
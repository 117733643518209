import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SubCategory } from '../models';

@Injectable({
	providedIn: 'root'
})
export class SubCategoryService {
	url = environment.api + 'category/';
	constructor(private http: HttpClient) { }

	public list(params: Params = null) {
		return this.http.get<{ count: number; data: SubCategory[] }>(this.url, { params: params });
	}

	public get(id: number) {
		return this.http.get<SubCategory>(this.url + id);
	}
	public update(id: number, data: Object) {
		return this.http.put(this.url + id, data);
	}
	public create(data: Object) {
		return this.http.post(this.url, data);
	}
	public delete(id: number) {
		return this.http.delete(this.url + id);
	}
}